import AxiosInstance from '@/plugins/AxiosInstance';
import ForageMethods from '@/common/enums/ForageMethods';

export default class BasicService {
  axios = AxiosInstance;

  constructor(resource) {
    if (!resource) throw new Error('Resource is not provided');
    this.resource = resource;
  }

  getEndPoint(id, endPoint, suffix) {
    if (id && !endPoint) return `${this.resource}/${id}`;
    if (id && endPoint && suffix === 'voucher')
      return `${endPoint}/${this.resource}/${suffix}`;
    if (id && endPoint && suffix)
      return `${endPoint}/${id}/${this.resource}/${suffix}`;
    if (id && endPoint) return `${endPoint}/${id}/${this.resource}`;

    return this.resource;
  }

  async useForage(method, { key, value, timestamp }) {
    const module = await import('localforage');
    const localforage = module?.default;

    const now = Math.floor(new Date().getTime());

    if (method === ForageMethods.GET) {
      const storedKeys = await localforage.keys();

      if (!storedKeys.includes(key)) return null;

      const storedValue = await localforage.getItem(key);

      if (now - storedValue.timestamp > timestamp) {
        await localforage.removeItem(key);
        return null;
      }

      return storedValue;
    }

    await localforage.setItem(key, { ...value, timestamp: now });
  }
}
