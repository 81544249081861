import PageService from "@/services/PageService";

class PagesRepository {
  async fetchPages() {
    const result = await PageService.fetch();

    if (!result.success) throw Error("Cannot fetch pages");
    return result.data;
  }

  async fetchPage(id = "") {
    if (!id) throw Error("Page id is not provided");
    const result = await PageService.get(id);

    if (!result.success) throw Error(`Cannot fetch page id: ${id}`);
    return result.data;
  }
}

export default new PagesRepository();
