<script setup>
import { onBeforeMount, onUnmounted, ref, inject } from "vue";
import VBurger from "@/components/own/navigation/V-Burger";
import VNavigation from "@/components/own/navigation/V-Navigation";

const showMenu = ref(false);
const changeShowMenu = () => (showMenu.value = !showMenu.value);

const emitter = inject("emitter");
emitter.on("click-menu", () => {
  if (showMenu.value) showMenu.value = !showMenu.value;
});

onBeforeMount(() => {
  window.addEventListener(
    "resize",
    () => {
      if (window.innerWidth > 768 && showMenu.value)
        showMenu.value = !showMenu.value;
    },
    { passive: true }
  );
});

onUnmounted(() => {
  if (typeof window !== "undefined") {
    window.removeEventListener("resize", () => {}, { passive: true });
  }
});
</script>

<template>
  <nav class="navbar">
    <div
      :class="{ 'navbar__wrapper--mobile': showMenu }"
      class="navbar__wrapper container"
    >
      <!-- LOGO - BRAND -->
      <V-Logo
        v-show="!showMenu"
        class="navbar__brand"
        height="122"
        width="127"
      />

      <!-- MENU -->
      <VNavigation :show="showMenu" class="navbar__nav" />

      <!-- HAMBURGER -->
      <VBurger
        :show="showMenu"
        class="navbar__burger"
        @click="changeShowMenu"
      />

      <div
        :class="{ 'navbar__button--mobile': showMenu }"
        class="navbar__button"
      >
        <V-Button
          :class="{ 'button--width100': showMenu }"
          :configRouter="{
            active: true,
            name: 'Shop',
          }"
          class="button--linear-gradient-blue button--padding-15-29"
        >{{ $t("navbar.button.text") }}
        </V-Button>
      </div>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
.navbar {
  display: flex;
  align-items: center;
  height: 100px;
  position: relative;
  z-index: 2;
  max-width: 1920px;
  left: 50%;
  transform: translateX(-50%);

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 40px;

    &--mobile {
      width: calc(100% - 100px);
      flex-wrap: wrap;
      background-color: #ffffff;
      padding: 25px;
      border-radius: 15px;
      position: relative;
      top: 35px;
      z-index: 2;
    }
  }

  &__button {
    @media screen and (max-width: 768px) {
      display: none;
    }

    &--mobile {
      margin-top: 20px;
      display: flex;
      order: 3;
      width: 100%;
    }
  }
}
</style>
