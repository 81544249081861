import useRedirect from '@/modules/useRedirect';
import { useToast } from 'vue-toastification';

const { redirect } = useRedirect();
const toast = useToast();

class ErrorService {
  handle(httpCode, data) {
    if (httpCode === 404) {
      redirect();
    }

    if (!data.success && data.data.error) {
      const err = data.data.error;
      toast.error(err);
    }
  }
}

export default new ErrorService();
