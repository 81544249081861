<script setup>
import { useRouter } from "vue-router";

defineProps({
  server: {
    type: Object,
    required: true,
    validator: value =>
      !value ? console.warn("server props is missing!") : true
  }
});

const router = useRouter();
</script>

<template>
  <div class="v-back">
    <a class="v-back__link" @click="router.go(-1)">
      <V-Icon class="v-back__icon" icon="fa-caret-left" iconType="fas" />
      <p class="v-back__text v-back__text--back">{{ $t("global.back") }}</p>
    </a>
    <div class="v-back__content">
      <img :src="server.server?.mainImage.path" alt="Obrazek serwera" class="v-back__image">
      <div class="v-back__information">
        <p class="v-back__nameServer">{{ server.server?.name }}</p>
        <p class="v-back__description">{{ $t("application.name") }}</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.v-back {
  flex-basis: 33.33%;
  text-align: left;
  align-self: center;
  margin: 25px 0;

  &__link {
    cursor: pointer;
    transition: all 0.3s linear;

    &:hover {
      transition: all 0.3s linear;
      opacity: 0.65;
    }
  }

  &__text {
    display: inline-block;

    &--back {
      font-family: $base__font-family-extra-bold;
      color: #ffffff;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: -0.01em;
    }
  }

  &__icon {
    color: #ffffff;
    position: relative;
    top: 2px;
    margin-right: 8px;
    font-size: 22px;
  }

  &__content {
    margin-top: 26px;
    display: flex;
    align-items: center;
  }

  &__image {
    width: 64px;
    height: 64px;
    border-radius: 15px;
    border: 3px solid #006ec9;
    box-sizing: border-box;
  }

  &__information {
    margin-left: 16px;
  }

  &__nameServer {
    font-family: $base__font-family-extra-bold;
    color: #ffffff;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.2px;
  }

  &__description {
    font-family: $base__font-family-light;
    color: #5a7184;
    font-size: 18px;
    line-height: 32px;
  }
}
</style>
