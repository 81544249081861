<script setup>
import { inject } from "vue";

defineProps({
  show: {
    type: Boolean,
    default: () => false
  }
});

const emitter = inject("emitter");
const clickMenu = () => {
  emitter.emit("click-menu");
};
</script>

<template>
  <div :class="{ 'v-nav-mobile': show }" class="v-nav">
    <router-link
      :class="{
        'v-nav__link': !show,
        'v-nav-mobile__link': show
      }"
      :to="{ name: 'Index' }"
      @click="clickMenu"
    >{{ $t("navbar.menu.index") }}
    </router-link
    >
    <router-link
      :class="{
        'v-nav__link': !show,
        'v-nav-mobile__link': show
      }"
      :to="{ name: 'Event' }"
      @click="clickMenu"
    >{{ $t("navbar.menu.event") }}
    </router-link
    >
    <router-link
      :class="{
        'v-nav__link': !show,
        'v-nav-mobile__link': show
      }"
      :to="{ name: 'Blog' }"
      @click="clickMenu"
    >{{ $t("navbar.menu.blog") }}
    </router-link
    >
    <router-link
      :class="{
        'v-nav__link': !show,
        'v-nav-mobile__link': show
      }"
      :to="{ name: 'Ranks' }"
      @click="clickMenu"
    >{{ $t("navbar.menu.ranks") }}
    </router-link
    >
    <router-link
      :class="{
        'v-nav__link': !show,
        'v-nav-mobile__link': show
      }"
      :to="{ name: 'Faq' }"
      @click="clickMenu"
    >{{ $t("navbar.menu.faq") }}
    </router-link
    >
  </div>
</template>

<style lang="scss" scoped>
.v-nav {
  @media screen and (max-width: 768px) {
    display: none;
  }

  @media screen and (max-width: 368px) {
    width: calc(100% - 65px);
  }

  &-mobile {
    display: flex;
    order: 2;
    flex-direction: column;
    width: 100%;
    height: auto;

    &__link {
      font-family: $base__font-family-extra-bold;
      color: #5a7184;
      opacity: 1;
      font-size: 18px;
      line-height: 26px;
      text-transform: uppercase;
      margin: 0 0 15px;
      position: relative;
      transition: all 0.3s linear;

      &:hover {
        color: #006ec9;
      }

      &:last-child {
        margin: 0;
      }

      &::before {
        height: 0;
      }
    }
  }

  &__link {
    font-family: $base__font-family-extra-bold;
    color: #ffffff;
    opacity: 0.5;
    font-size: 18px;
    line-height: 26px;
    text-transform: uppercase;
    margin: 0 15px;
    position: relative;
    transition: all 0.3s linear;

    &:hover {
      opacity: 1;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: -14px;
        width: 32px;
        height: 6px;
        border-radius: 10px;
        background: #006ec9;
      }
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: -14px;
      width: 0;
      height: 6px;
      border-radius: 10px;
      background: #006ec9;
      transition: all 0.3s linear;
    }
  }
}

.router-link-active {
  opacity: 1;

  &::before {
    width: 32px;
  }
}
</style>
