<script setup>
import {ref, watch} from 'vue';

const props = defineProps({
  active: {
    type: Boolean,
    default: () => false,
  },
});

const showModal = ref(false);

const closeModal = () => {
  showModal.value = false;
  document.body.classList.remove('modal-on');

  const voucher = document.querySelector('.voucher__form');
  if (voucher) voucher.reset();
};

watch(
    () => props.active,
    () => {
      document.body.classList.add('modal-on');
      showModal.value = !showModal.value;
    }
);
</script>

<template>
  <teleport v-if="showModal" to="body">
    <div :class="{ 'modal--disabled': !showModal }" class="modal">
      <div class="modal__content">
        <span class="modal__close" @click="closeModal">&times;</span>
        <div class="modal__scroll">
          <slot name="content"></slot>
        </div>
      </div>
    </div>
  </teleport>
</template>

<style lang="css">
body.modal-on {
  height: 100vh;
  overflow: hidden;
}
</style>

<style lang="scss">
.modal {
  position: fixed;
  z-index: 4999;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(31, 32, 41, 0.75);
  padding-right: 10px;
  overflow-y: auto;

  &--disabled {
    display: none;
  }

  &__content {
    position: relative;
    display: block;
    width: 100%;
    max-width: 672px;
    border: 3px solid #006ec9;
    border-radius: 15px;
    background: linear-gradient(180deg, #0d2436 0%, #002645 100%);
    margin: 0 15px;

  }

  &__scroll {
    padding: 38px 96px;
    box-sizing: border-box;
    max-height: calc(100vh - 25px);
    overflow-y: auto;

    @media screen and (max-width: 768px) {
      padding: 48px 28px;
    }
  }

  &__close {
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 15px;
    color: #ffffff;
    font-family: $base__font-family-extra-bold;
    font-size: 22px;
    transition: all 0.3s linear;

    &:hover {
      color: red;
    }
  }
}
</style>
