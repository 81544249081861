import { ref } from "vue";

export default function() {
  const request = ref(false);

  const useApi = async callback => {
    request.value = true;

    const response = await callback();

    request.value = false;
    return response;
  };

  return {
    useApi,
    request
  };
}
