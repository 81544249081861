import BasicService from '@/services/main/BasicService';
import ForageMethods from '@/common/enums/ForageMethods';

const TIMESTAMP_NAME = 'timestamp';

export default class ResourceService extends BasicService {
  constructor(resource) {
    super(resource);
  }

  async #cacheResponse(url, config = {}) {
    const useCache = Object.keys(this.constructor).includes(TIMESTAMP_NAME);
    let serializedParams = url;

    if (config.params) {
      const paramsKeys = Object.keys(config.params).sort();

      paramsKeys.forEach((key) => {
        serializedParams = serializedParams.concat(
          `${key}=${config.params[key] || ''}`
        );
      });
    }

    try {
      const { timestamp } = this.constructor;
      const result = await this.useForage(ForageMethods.GET, {
        key: serializedParams,
        timestamp,
      });

      if (result) return result;

      const response = await this.axios.get(url, config);

      if (useCache) {
        await this.useForage(ForageMethods.SET, {
          key: serializedParams,
          value: response,
          timestamp,
        });
      }

      return response;
    } catch (error) {
      return error;
    }
  }

  fetch(config = {}, additionalParams = '') {
    return this.#cacheResponse(this.getEndPoint() + additionalParams, config);
  }

  get(id = '', config = {}, endPoint = '', suffix = '') {
    return this.#cacheResponse(this.getEndPoint(id, endPoint, suffix), config);
  }
}
