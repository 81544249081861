import router from "../router";

export default function() {
  const redirect = async () => {
    await router.push({ name: "404" });
  };

  return {
    redirect
  };
}
