<script setup>
import { computed } from "vue";
import useSocialMedia from "@/modules/SocialMedia/useSocialMedia";
import usePages from "@/modules/Pages/usePages";
import staticPage from "@/static/staticPage";
import VColumnList from "@/components/own/footer/V-ColumnList";
import VColumnListItem from "@/components/own/footer/V-ColumnListItem";

// SOCIAL MEDIA
const { socialMedia, fetchSocialMedia } = useSocialMedia();
fetchSocialMedia();

// PAGES
const { pages, fetchPages } = usePages();
fetchPages();

const prefixIcon = computed(() => {
  return "fa-";
});
const date = new Date().getFullYear();
</script>

<template>
  <footer class="footer">
    <div class="footer__wrapper container">
      <div class="footer__information information">
        <V-Logo height="122" width="127" />
        <p class="information__text">
          {{ $t("footer.information.text") }}
        </p>

        <div v-show="socialMedia.length" class="footer__social-media">
          <a
            v-for="(social, index) in socialMedia"
            :key="index"
            :href="social.url"
            class="footer__link"
          >
            <V-Icon
              :icon="prefixIcon + social.icon"
              class="footer__icon"
              iconType="fab"
            />
          </a>
        </div>
      </div>

      <VColumnList :title="$t('application.name')" class="footer__column">
        <VColumnListItem
          v-for="(page, index) in staticPage"
          :key="index"
          :page="page"
        />
      </VColumnList>

      <VColumnList
        :title="$t('footer.staticPage.title')"
        class="footer__column"
      >
        <VColumnListItem
          v-for="(page, index) in pages"
          :key="index"
          :page="page"
        />
      </VColumnList>

      <div class="footer__copyright copyright">
        <h3 class="copyright__text copyright__text--bold">
          {{ $t("application.name") }}
        </h3>
        <p
          class="
            copyright__text copyright__text--light copyright__text--color-grey
          "
        >
          © {{ date }} {{ $t("footer.copyright.text") }}
        </p>
      </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
.footer {
  display: block;
  width: 100%;
  background-color: #071a29;
  position: relative;

  &__wrapper {
    display: flex;
    justify-content: space-between;
    padding: 100px 0 50px;

    @media screen and (max-width: 1024px) {
      flex-wrap: wrap;
    }

    @media screen and (max-width: 530px) {
      padding: 50px 0;
    }
  }

  &__column {
    margin-top: 15px;
    @media screen and (max-width: 574px) {
      flex-basis: 100%;
    }
  }

  &__copyright {
    margin-top: 15px;
  }

  &__link {
    display: inline-block;
    margin-bottom: 15px;

    &:hover {
      color: #ffffff;
    }
  }

  &__icon {
    color: #959ead;
    font-size: 32px;
    transition: all 0.3s linear;
    margin-right: 16px;

    &:hover {
      transition: all 0.3s linear;
      color: #ffffff;
    }
  }

  &__information {
    max-width: 200px;
  }
}

.information {
  &__text {
    margin-top: 30px;
    font-family: $base__font-family-light;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.355556px;
    color: #959ead;
    margin-bottom: 29px;
  }
}

.copyright {
  &__text {
    font-family: $base__font-family-extra-bold;
    color: #ffffff;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.355556px;

    &--light {
      font-family: $base__font-family-light;
    }

    &--color-grey {
      color: #959ead;
    }
  }
}
</style>
