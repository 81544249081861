const staticPage = [
  {
    id: 1,
    name: "Sklep",
    router: "Shop"
  },
  {
    id: 2,
    name: "Rankingi",
    router: "Ranks"
  },
  {
    id: 3,
    name: "Blog",
    router: "Blog"
  }
];

export default staticPage;
