import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  // Strona Główna
  {
    path: '/',
    name: 'Index',
    component: () =>
      import(/* webpackChunkName: "index" */ '@/views/Index.vue'),
  },
  // Sklep
  {
    path: '/shop',
    name: 'Shop',
    component: () => import(/* webpackChunkName: "shop" */ '@/views/Shop.vue'),
  },
  {
    path: '/shop/:id',
    name: 'ShopDetails',
    component: () =>
      import(/* webpackChunkName: "shop" */ '@/views/shop/ShopDetails.vue'),
  },
  // Eventy
  {
    path: '/eventy',
    name: 'Event',
    component: () =>
      import(/* webpackChunkName: "event" */ '@/views/Event.vue'),
  },
  {
    path: '/event/:id',
    name: 'EventDetails',
    component: () =>
      import(/* webpackChunkName: "event" */ '@/views/event/EventDetails.vue'),
  },
  // Blog
  {
    path: '/blog',
    name: 'Blog',
    component: () => import(/* webpackChunkName: "blog" */ '@/views/Blog.vue'),
  },
  {
    path: '/blog/:id',
    name: 'BlogDetails',
    component: () =>
      import(/* webpackChunkName: "blog" */ '@/views/blog/BlogDetails.vue'),
  },
  // Rankingi
  {
    path: '/ranking',
    name: 'Ranks',
    component: () => import(/* webpackChunkName: "rank" */ '@/views/Ranks.vue'),
  },
  {
    path: '/ranking/:id',
    name: 'RankDetails',
    component: () =>
      import(/* webpackChunkName: "rank" */ '@/views/rank/RankDetails.vue'),
  },
  // FAQ
  {
    path: '/faq',
    name: 'Faq',
    component: () => import(/* webpackChunkName: "faq" */ '@/views/Faq.vue'),
  },
  // Pages
  {
    path: '/page/:id',
    name: 'Page',
    component: () => import(/* webpackChunkName: "page" */ '@/views/Page.vue'),
  },
  // Szczegóły Serwera
  {
    path: '/server/:id',
    name: 'ServerDetails',
    component: () =>
      import(
        /* webpackChunkName: "server" */ '@/views/server/ServerDetails.vue'
      ),
  },
  {
    path: '/reflink/:slug',
    name: 'CheckReflink',
    component: () =>
      import(/* webpackChunkName: "reflink" */ '@/views/Reflink.vue'),
  },
  // Payment
  {
    path: '/order/summary/:slug',
    name: 'Payment',
    component: () =>
      import(/* webpackChunkName: "payment" */ '@/views/Payment.vue'),
  },
  // 404 - NOT FOUND PAGE
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () =>
      import(/* webpackChunkName: "error" */ '@/views/error/404.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash)
      return document
        .querySelector(to.hash)
        .scrollIntoView({ behavior: 'smooth' });
    else return savedPosition || { left: 0, top: 0 };
  },
});

export default router;
