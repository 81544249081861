<script setup>
import { useRouter } from 'vue-router';

const router = useRouter();

defineProps({
  configRouter: {
    type: Object,
    default: () => {
      return {
        active: false,
        name: '',
        params: {},
      };
    }
  },
});
</script>

<template>
  <a
    v-if="configRouter.active"
    :href="
      router.resolve({ name: configRouter.name, params: configRouter.params })
        .href
    "
    class="button button--border-radius-15 button--text-center"
  >
    <slot></slot>
  </a>

  <div v-else class="button button--border-radius-15 button--text-center">
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
.button {
  display: inline-block;
  font-family: $base__font-family-extra-bold;
  font-size: 14px;
  color: #ffffff;
  text-transform: uppercase;
  transition: all 0.3s linear;

  &--text-center {
    text-align: center;
  }

  &--border-radius-15 {
    border-radius: 15px;
  }

  &--padding-15-39 {
    padding: 15px 39px;
  }

  &--padding-15-29 {
    padding: 15px 29px;
  }

  &--padding-15-24 {
    padding: 15px 24px;
  }

  &--padding-15-0 {
    padding: 15px 0;
  }

  &--display-line-block {
    display: inline-block;
  }

  &--width100 {
    width: 100%;
  }
}

a.button {
  &--linear-gradient-blue {
    background: linear-gradient(
      180deg,
      #006ec9 0%,
      rgba(0, 110, 201, 0.5) 100%
    );
    border: 1px solid #006ec9;

    &:hover {
      box-shadow: 0 15px 35px rgba(0, 110, 201, 0.5);
    }
  }

  &--linear-gradient-green {
    background: linear-gradient(
      180deg,
      #1ccc0d 0%,
      rgba(28, 204, 13, 0.6) 100%
    );
    border: 1px solid #1ccc0d;

    &:hover {
      box-shadow: 0 15px 35px rgba(28, 204, 13, 0.5);
    }
  }

  &--primary-color {
    background: #006ec9;

    &:hover {
      box-shadow: 0 15px 35px #006ec9;
    }
  }
}

div.button {
  &--linear-gradient-brown {
    background: linear-gradient(
      180deg,
      #cc690d 0%,
      rgba(204, 105, 13, 0.6) 100%
    );
    border: 1px solid #cc690d;
  }
}
</style>
