import ResourceService from '@/services/main/ResourceService';
import {
  MINUTES_IN_HOUR,
  SECONDS_IN_MINUTE,
} from '@/common/constants/time/time';

class SocialMediaService extends ResourceService {
  static timestamp = SECONDS_IN_MINUTE * MINUTES_IN_HOUR;

  constructor() {
    super('socialMedia');
  }
}

export default new SocialMediaService();
