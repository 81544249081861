import SocialMediaService from "@/services/SocialMediaService";

class SocialMediaRepository {
  async fetchSocialMedia() {
    const result = await SocialMediaService.fetch();

    if (!result.success) throw Error("Cannot fetch social media");
    return result.data;
  }
}

export default new SocialMediaRepository();
