import { ref } from "vue";
import useAxios from "@/common/composables/useAxios";
import SocialMediaRepository from "@/modules/SocialMedia/helpers/SocialMediaRepository";

const { useApi, request } = useAxios();
const socialMedia = ref([]);

export default function () {
  const fetchSocialMedia = async () => {
    socialMedia.value = await useApi(() =>
      SocialMediaRepository.fetchSocialMedia()
    );
  };

  return {
    socialMedia,
    fetchSocialMedia,
    socialMediaRequest: request,
  };
}
