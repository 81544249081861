<script setup>
import { onMounted, reactive } from "vue";

const data = reactive({
  isLoaded: false
});

onMounted(() => {
  document.onreadystatechange = () => {
    if (document.readyState === "complete") {
      data.isLoaded = true;
    }
  };
});
</script>

<template>
  <transition name="fade">
    <div v-if="!data.isLoaded" class="loader-page">
      <img
        width="127"
        height="122"
        class="loader-page__image"
        :src="require('@/assets/img/layout/logo.png')"
        alt="Logo"
      />
      <p class="loader-page__text">{{ $t('loading.loading-04.text') }}.</p>
    </div>
  </transition>
</template>

<style lang="scss">
.loader-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background-color: #0d2436;

  &__image {
    max-height: 100%;
    max-width: 100%;
  }

  &__text {
    font-family: $base__font-family-light;
    margin-top: 25px;
    color: #ffffff;
    font-size: 14px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
