<script setup>
import { computed } from "vue";
import { useRouter } from "vue-router";

const props = defineProps({
  page: {
    type: Object,
    required: true,
    validator: value => (!value ? console.warn("page props is missing!") : true)
  }
});

const router = useRouter();

const routeName = computed(() => {
  if (props.page.router)
    return router.resolve({ name: props.page.router }).href;
  return router.resolve({ name: "Page", params: { id: props.page.id } }).href;
});

const title = computed(() => {
  if (props.page.name) return props.page.name;
  return props.page.title;
});
</script>

<template>
  <li class="v-column__item">
    <a :href="routeName" class="v-column__link">{{ title }}</a>
  </li>
</template>

<style lang="scss" scoped>
.v-column {
  &__item {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__link {
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.355556px;
    display: block;
    font-family: $base__font-family-light;
    color: #959ead;

    &:hover {
      color: #ffffff;
    }
  }
}
</style>
