import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import mitt from 'mitt';
import i18n from './i18n';
import Toast from 'vue-toastification';

// OTHER
import VCalendar from 'v-calendar';
import VueEasyLightbox from 'vue-easy-lightbox';
import { dom, library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

// LAYOUTS
import AppLayout from '@/layouts/AppLayout';

// Base components
import VLogo from '@/components/base/logo/V-Logo';
import VContent from '@/components/base/render/V-Content';
import VButton from '@/components/base/button/V-Button';
import VButtonLink from '@/components/base/button/V-ButtonLink';
import VIcon from '@/components/base/icon/V-Icon';
import VSectionHeader from '@/components/base/section/V-SectionHeader';
import VSectionHeaderDetails from '@/components/base/section/V-SectionHeaderDetails';
import VBack from '@/components/base/section/V-Back';
import VLoading from '@/common/components/loader/V-Loading';
import VModal from '@/components/base/modal/V-Modal';

import 'vue3-carousel/dist/carousel.css';
import 'vue-toastification/dist/index.css';
import 'v-calendar/dist/style.css';

const app = createApp(App);

// EVENT BUS
const emitter = mitt();
app.provide('emitter', emitter);

// FONT AWESOME ICON
library.add(fas);
library.add(fab);
library.add(far);
dom.watch();

app.component('font-awesome-icon', FontAwesomeIcon);

// LAYOUT
app.component('AppLayout', AppLayout);

app.component('VLogo', VLogo);

app.component('VContent', VContent);

app.component('VButton', VButton);
app.component('VButtonLink', VButtonLink);
app.component('VIcon', VIcon);
app.component('VSectionHeader', VSectionHeader);
app.component('VSectionHeaderDetails', VSectionHeaderDetails);
app.component('VBack', VBack);
app.component('VLoading', VLoading);
app.component('VModal', VModal);

// NOTIFICATION SETTINGS
const options = {
  transition: 'Vue-Toastification__bounce',
  maxToasts: 20,
  newestOnTop: true,
  position: 'bottom-right',
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: 'button',
  icon: 'fas fa-rocket',
  rtl: false,
};

app
  .use(i18n)
  .use(router)
  .use(VCalendar, {})
  .use(VueEasyLightbox)
  .use(Toast, options)
  .mount('#app');
