import axios from 'axios';
import { useCookies } from 'vue3-cookies';
import errorService from '@/services/ErrorService';

const { cookies } = useCookies();

export const setBaseUrl = () => {
  if (process.env.NODE_ENV !== 'development') {
    return process.env.VUE_APP_API_URL_PRODUCTION;
  }
  return process.env.VUE_APP_API_URL_LOCAL;
};

const AxiosInstance = axios.create({
  baseURL: setBaseUrl(),
  timeout: 10000,
  maxBodyLength: 5000,
  maxContentLength: 5000,

  headers: {
    'Content-type': 'application/json; charset=UTF-8',
  },
});

AxiosInstance.interceptors.response.use(
  ({ data }) => data,
  (error) => {
    errorService.handle(error.response?.status, error.response.data);
    throw error;
  }
);

AxiosInstance.interceptors.request.use(
  (request) => {
    if (!cookies.get('X-SessionUid'))
      cookies.set('X-SessionUid', Math.ceil(Math.random() * 1000000));
    request.headers['X-SessionUid'] = cookies.get('X-SessionUid');
    return request;
  },
  (error) => {
    errorService.handle(error.response?.status, error.response.data);
    throw error;
  }
);

export default AxiosInstance;
