import { ref } from "vue";
import useAxios from "@/common/composables/useAxios";
import PagesRepository from "@/modules/Pages/helpers/PagesRepository";

const { useApi, request } = useAxios();
const pages = ref([]);

export default function () {
  const fetchPages = async () => {
    pages.value = await useApi(() => PagesRepository.fetchPages());
  };

  return {
    pages,
    fetchPages,
    pagesRequest: request,
  };
}
